<template>
    <div class="widgetContainer">
        <div v-if="showTitle" class="header" >
            <slot name="header">
                <span @click="$emit('click')">{{title}}</span>
            </slot>
        </div>
        <div v-if="showDecoration" class="decoration">
            <slot name="decoration">
                <dv-decoration-3 class="default-decoration" />
            </slot>
        </div>
        <div id="content" class="content">
            <dv-border-box-6 v-if="showBorder" :color="['grey']" class="content-inner">
                <slot name="content"></slot>
            </dv-border-box-6>
            <div v-else class="content-inner">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WidgetFramework',
    props: {
        showTitle: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        },
        showDecoration: {
            type: Boolean,
            default: true,
        },
        showBorder: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        resize() {},
    },
};
</script>

<style lang="less" scoped>
.widgetContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #ffffff;

    .header {
        font-size: 20px;
        padding: 5px 5px 0px 5px;
    }

    .decoration {
        padding: 5px;

        .default-decoration {
            width: 150px;
            height: 25px;
        }
    }

    .content {
        overflow: auto;
        flex: 1;
        box-sizing: border-box;
        width: 100%;

        /deep/ .dv-border-box-6 {
            & > .border-box-content {
                position: absolute;
                margin: 15px;
                height: calc(100% - 30px);
                width: calc(100% - 30px);
            }
        }
    }
    .content-inner {
        width: 100%;
        height: 100%;
    }
}
</style>
