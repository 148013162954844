<template>
    <table v-if="treeData.name">
        <tr>
            <td :colspan="Array.isArray(treeData.children) ? treeData.children.length * 2 : 1"
                :class="{parentLevel: Array.isArray(treeData.children) && treeData.children.length, extend: Array.isArray(treeData.children) && treeData.children.length && treeData.extend}"
            >
                <div :class="{node: true, hasMate: treeData.mate}">
                    <div class="person"
                         :class="Array.isArray(treeData.class) ? treeData.class : []"
                         @click="$emit('click-node', treeData)"
                    >
                        <div class="avat" v-if="treeData.image_url">
                            <img :src="treeData.image_url"/>
                        </div>
                        <div class="name">{{ treeData.name }}</div>
                    </div>
                    <template v-if="Array.isArray(treeData.mate) && treeData.mate.length">
                        <div class="person" v-for="(mate, mateIndex) in treeData.mate" :key="treeData.name+mateIndex"
                             :class="Array.isArray(mate.class) ? mate.class : []"
                             @click="$emit('click-node', mate)"
                        >
                            <div class="avat" v-if="mate.image_url">
                                <img :src="mate.image_url"/>
                            </div>
                            <div class="name">{{ mate.name }}</div>
                        </div>
                    </template>
                </div>
                <div class="extend_handle" v-if="Array.isArray(treeData.children) && treeData.children.length"
                     @click="toggleExtend(treeData)"></div>
            </td>
        </tr>
        <tr v-if="Array.isArray(treeData.children) && treeData.children.length && treeData.extend">
            <td v-for="(children, index) in treeData.children" :key="index" colspan="2" class="childLevel">
                <TreeChart :json="children" @click-node="$emit('click-node', $event)"/>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    name: 'TreeChart',
    props: ['json'],
    data() {
        return {
            treeData: {},
        };
    },
    watch: {
        json: {
            handler: function(Props) {
                let extendKey = function(jsonData) {
                    jsonData.extend = (jsonData.extend === void 0 ? true : !!jsonData.extend);
                    if (Array.isArray(jsonData.children)) {
                        jsonData.children.forEach(c => {
                            extendKey(c);
                        });
                    }
                    return jsonData;
                };
                if (Props) {
                    this.treeData = extendKey(Props);
                }
            },
            immediate: true,
        },
    },
    methods: {
        toggleExtend: function(treeData) {
            treeData.extend = !treeData.extend;
            this.$forceUpdate();
        },
    },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/variables.less';

table {
    border-collapse: separate !important;
    border-spacing: 0 !important;

    &>tr {
        &>td{
            position: relative;
            vertical-align: top;
            padding: 0 0 50px 0;
            text-align: center;

            .node {
                position: relative;
                display: inline-block;
                margin: 0 1em;
                box-sizing: border-box;
                text-align: center;

                .person {
                    position: relative;
                    display: inline-block;
                    z-index: 2;
                    min-width: 6em;
                    overflow: hidden;

                    .avat {
                        display: block;
                        width: 3em;
                        height: 3em;
                        margin: auto;
                        overflow: hidden;
                        box-sizing: border-box;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .name {
                        height: 2em;
                        line-height: 2em;
                        overflow: hidden;
                        width: 100%;
                    }
                }

                &.hasMate::after {
                    content: "";
                    position: absolute;
                    left: 2em;
                    right: 2em;
                    top: 2em;
                    border-top: 2px solid @cyan;
                    z-index: 1;
                }
            }

            .extend_handle {
                position: absolute;
                left: 50%;
                bottom: 30px;
                width: 10px;
                height: 10px;
                padding: 10px;
                transform: translate3d(-15px, 0, 0);
                cursor: pointer;

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    border: 2px solid;
                    border-color: @cyan @cyan transparent transparent;
                    transform: rotateZ(135deg);
                    transform-origin: 50% 50% 0;
                    transition: transform ease 300ms;
                }
                &:hover:before {
                    border-color: #333 #333 transparent transparent;
                }
            }

            &.extend {
                .extend_handle:before {
                    transform: rotateZ(-45deg);
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 15px;
                    height: 15px;
                    border-left: 2px solid @cyan;
                    transform: translate3d(-1px, 0, 0)
                }
            }

            &.childLevel {
                padding: 0;

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 100%;
                    height: 15px;
                    border-left: 2px solid @cyan;
                    transform: translate3d(-1px, 0, 0)
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -15px;
                    border-top: 2px solid @cyan;
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                    &:after {
                        left: 50%;
                        height: 15px;
                        border: 2px solid;
                        border-color: @cyan transparent transparent @cyan;
                        border-radius: 6px 0 0 0;
                        transform: translate3d(1px, 0, 0)
                    }
                    &.childLevel:last-child::after{
                        left: auto;
                        border-radius: 0;
                        border-color: transparent @cyan transparent transparent;
                        transform: translate3d(1px, 0, 0)
                    }
                }


                &:last-child{
                    &:before {
                        display: none;
                    }
                    &:after {
                        right: 50%;
                        height: 15px;
                        border: 2px solid;
                        border-color: @cyan @cyan transparent transparent;
                        border-radius: 0 6px 0 0;
                        transform: translate3d(-1px, 0, 0)
                    }
                }
            }
        }
    }
}
</style>
