<!--组织架构-->

<template>
    <widget-framework v-if="dataLoaded" title="组织架构" :show-border="true" @click="$router.push('/sys/orgUnit')">
        <template #content>
            <div class="orgTreeContainer">
                <div class="floatingFrame">
                    <dv-border-box-8 class="dv-border-box">
                        <div class="dv-box">
                            <span class="title">企业成员：</span>
                            <span class="value">{{userAmount}}人</span>
                        </div>
                    </dv-border-box-8>
                </div>
                <tree-chart :json="treeData"></tree-chart>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import TreeChart from './TreeChart';

export default {
    name: 'Organization',
    components: {
        WidgetFramework,
        TreeChart
    },
    data() {
        return {
            dataLoaded: false,
            treeData: [],
            userAmount: null
        };
    },
    methods: {
        convertToTreeData(node) {
            if (!node) {
                return null;
            }

            const nodeChildren = node.children || [];
            //人员中只显示机构负责人
            const children = nodeChildren.filter(child => child.nodeType !== 9 || child.isLeader === 1).map(child => {
                return this.convertToTreeData(child);
            });
            return {
                name: node.name,
                children: children.length > 0 ? children : null,
                class: ['org-node-' + node.nodeType],
                image_url: node.nodeType !== 9 ? require(`@/assets/images/widget/org-tree-node.png`) : ''
            };
        }
    },
    mounted() {
        this.$client.getOrgTree().then((ret) => {
            const {userAmount, orgUserTree} = ret.data;
            const treeData = this.convertToTreeData(orgUserTree);
            this.treeData = treeData;
            this.userAmount = userAmount;
            this.dataLoaded = true;
        });
    }
};
</script>

<style scoped lang="less">
@import '~@/assets/style/variables.less';

.orgTreeContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 10px;
    color: @cyan;
    display: flex;
    align-content: center;
    justify-content: center;

    .floatingFrame {
        display: block;
        position: fixed;
        top: 80px;
        right: 25px;
        height: 45px;
        width: 140px;
        color: red;

        .dv-border-box {
            width: 100%;
            height: 100%;

            .dv-box {
                display: inline-flex;
                height: 100%;
                width: 100%;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .title {
                    color: @blue;
                    font-size: 12px;
                }

                .value {
                    color: @yellow;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
